import React, { useState } from 'react';
import styled from 'styled-components';
import { TariffsItem } from '@/store/Tariffs/types';
import { ButtonWrapper } from '@/pages/account';
import BaseButton from '@/components/ui/BaseButton';
import { CoachPopupCloseBtn } from '@/components/ui/WorksheetContent/CoachPopup';
import { useRouter } from 'next/router';

const NewFlexItem: React.FC<TariffsItem> = ({
                                              id,
                                              name,
                                              image,
                                              price,
                                              home_desc,
                                              description,
                                              color
                                            }): React.ReactElement => {
  const [status, setStatus] = useState<boolean>(false);
  const router = useRouter();

  const handleClickOpen = () => {
    document.body.style.overflow = 'hidden';

    setStatus(true);
  };

  const handleClickMore = () => {
    document.body.style.overflow = 'scroll';

    router.push('/auth');
  };

  const handleClickClose = () => {
    document.body.style.overflow = 'scroll';

    document.body.style.overflow === 'scroll' && setStatus(false);
  };


  return (
    <>
      <FlexItem id={id} key={name} bg={color as string}>
        <FlexTextWrapper>
          <FlexItemTitle>{name}</FlexItemTitle>
          <FlexItemText>{home_desc}</FlexItemText>
        </FlexTextWrapper>
        <FlexImageWrapper>
          <FlexItemImage src={image as string} />
        </FlexImageWrapper>
        <FlexItemButton onClick={() => handleClickOpen()} />
      </FlexItem>

      <PopupContainer st={status}>
        <FlexItemPopup>
          <PopupFlexItemTitle>{name}</PopupFlexItemTitle>
          <PopupFlexTextWrapper>
            <PopupFlexItemSubText>Что вы получите:</PopupFlexItemSubText>
            <FlexItemText>{description}</FlexItemText>
          </PopupFlexTextWrapper>

          <PopupFlexItemPriceInfo>
            <PopupFlexItemPriceTrial>Первая неделя после регистрации бесплатно, затем</PopupFlexItemPriceTrial>
            <PopupFlexItemPrice>{price + ' ₽/30 дней'}</PopupFlexItemPrice>
          </PopupFlexItemPriceInfo>

          <PopupFlexButtonWrapper>
            <PopupFlexButton onClick={() => handleClickMore()}>Попробовать</PopupFlexButton>
          </PopupFlexButtonWrapper>

          <PopupFlexCloseButton onClick={() => handleClickClose()} />
        </FlexItemPopup>
      </PopupContainer>
    </>
  );
};

const FlexItem = styled.li<{ bg?: string }>`
    display: flex;
    width: 100%;
    height: auto;
    max-width: 390px;
    min-height: 300px;
    padding: 18px 13px;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(30px);
    border-radius: 16px;
    position: relative;

    :after,
    :before {
        content: '';
        width: 215px;
        height: 215px;
        background: radial-gradient(50% 50% at 50% 50%, rgba(178, 58, 204, 0.25) 0%, rgba(178, 58, 204, 0) 100%);
        background: ${({ bg }) => bg ? `radial-gradient(50% 50% at 50% 50%, ${bg + '40'} 0%, ${bg + '00'} 100%)` : 'none'};
        position: absolute;
        top: 40px;
    }

    :before {
        left: -100px;
    }

    :after {
        right: -100px;
    }
`;

const FlexTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;
`;

const FlexItemTitle = styled.h3`
    font-size: 19px;
    font-weight: 400;
    margin: 0 0 10px 0;

    @media (min-width: 390px) {
        font-size: 23px;
    }
`;

const FlexItemText = styled.p`
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    @media (min-width: 390px) {
        font-size: 13px;
        line-height: 19px;
    }
`;

const FlexImageWrapper = styled.div`
    width: 100px;
    height: 100px;
    display: block;
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: -1;
`;

const FlexItemImage = styled.img`
    width: 100%;
    height: auto;
    max-width: 100%;
    min-height: 100%;
    display: block;
    object-fit: cover;
`;

const FlexItemButton = styled.a`
    width: 52px;
    height: 52px;
    display: block;
    background-image: url('/icons/arrows/arrow-right-circle.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 100;
`;

const PopupContainer = styled.div<{ st: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    opacity: ${({ st }) => st ? '1' : '0'};
    transition: 300ms all ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 200;
    pointer-events: ${({ st }) => st ? 'auto' : 'none'};
`;

const FlexItemPopup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 360px;
    padding: 18px 13px;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(30px);
    border-radius: 16px;
    position: relative;
`;

const PopupFlexTextWrapper = styled(FlexTextWrapper)`
    margin: 0 0 20px 0;
`;

const PopupFlexItemTitle = styled(FlexItemTitle)`
    font-size: 22px;
    font-weight: 600;
    text-align: center;
`;

const PopupFlexItemSubText = styled(FlexItemText)`
    font-size: 16px;
    font-weight: 600;
    color: #13ffee;
    margin: 0 0 5px 0;
`;

const PopupFlexItemPriceInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 20px 0;
`;

const PopupFlexItemPriceTrial = styled(PopupFlexItemSubText)`
    text-align: center;
`;

const PopupFlexItemPrice = styled(PopupFlexItemSubText)`
    font-weight: 500;
    text-align: center;
`;

const PopupFlexButtonWrapper = styled(ButtonWrapper)`
    margin: 0;
`;

const PopupFlexButton = styled(BaseButton)``;

const PopupFlexCloseButton = styled(CoachPopupCloseBtn)``;

export {
  FlexItem,
  FlexItemTitle,
  FlexItemText
};

export default NewFlexItem;