import React from 'react';
import styled from 'styled-components';
import FlexContainer from '@/components/ui/FlexContainer';
import TitleH2 from '@/components/ui/Title';
import FlexProps from '@/interfaces/FlexSection';
import { TariffsItem } from '@/store/Tariffs/types';
import NewFlexItem from '@/components/ui/FlexSection/NewFlexItem/NewFlexItem';
import { FlexSectionInfoText } from '@/pages/index';

const FlexSection: React.FC<FlexProps> = ({ items }): React.ReactElement => {

  const renderFlexItem = (items: TariffsItem[]): React.ReactElement[] => {
    return items.map(
      ({
         id,
         name,
         price,
         image,
         description,
         home_desc,
         color
       }: TariffsItem): React.ReactElement => (
        <NewFlexItem id={id} name={name} home_desc={home_desc} description={description} price={price} image={image}
                     color={color}
                     key={id} />
      )
    );
  };

  return (
    <FlexSectionWrapper>
      <FlexSectionTitle>Тарифы</FlexSectionTitle>
      <FlexSectionInfoText>
          Спортадиум предлагает три тарифа на выбор: похудение, набор мышечной массы и ЛФК. Каждый из тарифов соответствует цели - потеря лишнего веса, увеличение мышечной массыили лечебная физическая культура.
          Цена подписки зависит от тарифа, так как каждая цель отличается по сложности и объёму работы тренера.
      </FlexSectionInfoText>
      <FlexSectionList>{renderFlexItem(items)}</FlexSectionList>

      <FlexSectionInfoText>
          Наши тренеры подберут эффективную
          стратегию достижения цели с учетом
          индивидуальных особенностей.
          В этом им поможет многолетний опыт,
          а подтверждением их навыков служат
          успешные примеры работ.
      </FlexSectionInfoText>
    </FlexSectionWrapper>
  );
};

const FlexSectionWrapper = styled(FlexContainer)`
    flex-direction: column;
`;

const FlexSectionTitle = styled(TitleH2)``;

const FlexSectionList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding: 0 23px;
    margin: 0 0 50px 0;
`;

export default FlexSection;
