import React, { RefObject, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import useTypedDispatch from '@/hooks/useTypedDispatch';
import { QuestionsItem } from '@/store/FAQ/types';
import { toggleLoader } from '@/store/Loader';
import faqsProps from '@/interfaces/faqs';
import { InfoItemText, InfoListItem, InfoButton } from '@/components/ui/InfoSection';
import TitleH2 from '@/components/ui/Title';

const FrequentlyAskedQuestions: React.FC<faqsProps> = ({ items }): React.ReactElement => {
  const dispatch = useTypedDispatch();
  const [allQuestions, setAllQuestions] = useState<QuestionsItem[] | []>([]);
  const [visibleQuestions, setVisibleQuestions] = useState<QuestionsItem[] | []>([]);
  const [activeItemId, setActiveItemId] = useState<number | null>(null);
  const titleQuestionRef = useRef<HTMLDivElement | null>(null);
  const isShowMore = visibleQuestions && visibleQuestions.length <= 5;
  const buttonText = isShowMore ? 'Открыть больше' : 'Скрыть';
  const textRefs = useRef<{ [key: string]: RefObject<HTMLDivElement> }>({});
  const arrowRef = useRef<{ [key: string]: RefObject<HTMLDivElement> }>({});

  useEffect(() => {
    if (items) {
      const questionArray = Object.values(items);
      const visibleElements = gsap.utils.toArray(questionArray).slice(0, 5);
      setAllQuestions(questionArray as QuestionsItem[]);
      setVisibleQuestions(visibleElements as QuestionsItem[]);
    }
  }, [items]);

  const renderQuestions = (items: QuestionsItem[]): React.ReactElement[] => {
    return items.map(
      (item: QuestionsItem): React.ReactElement => {
        const idKey = String(item.id);

        if (!textRefs.current[idKey]) {
          textRefs.current[idKey] = React.createRef();
        }

        if (!arrowRef.current[idKey]) {
          arrowRef.current[idKey] = React.createRef();
        }

        const handleClick = (id: number) => {
          const ref = textRefs.current[id.toString()];
          const arrRef = arrowRef.current[id.toString()];

          if (ref && ref.current) {
            const isExpanding = id !== activeItemId;
            gsap.to(ref.current, {
              height: isExpanding ? 'auto' : 0,
              opacity: isExpanding ? 1 : 0
            });
            gsap.to(arrRef.current, {
              duration: 0.1,
              transform: isExpanding ? 'rotate(180deg)' : 'rotate(0deg)'
            });

            setActiveItemId(isExpanding ? id : null);
          }
        };

        return (
          <QuestionsListItem id={String(item.id)} key={item.id}
                             onClick={() => handleClick(item.id as number)}>
            <QuestionsItemTitle>{item.question}</QuestionsItemTitle>
            <QuestionsItemTextWrapper ref={textRefs.current[item.id as number]}>
              <QuestionsItemText>
                {item.answer}
              </QuestionsItemText>
            </QuestionsItemTextWrapper>

            <ArrowItem ref={arrowRef.current[item.id as number]} />
          </QuestionsListItem>
        );
      }
    );
  };

  const renderMoreItems = (arr1: QuestionsItem[], arr2: QuestionsItem[]) => {
    const newArr = [...arr2];

    for (let i = 0; i < arr1.length; i++) {
      let found = false;

      for (let j = 0; j < arr2.length; j++) {
        if (arr1[i].id === arr2[j].id) {
          found = true;
          break;
        }
      }

      if (!found) {
        newArr.push(arr1[i]);
      }
    }

    return newArr;
  };

  const handleClickMoreButton = (arr1: QuestionsItem[], arr2: QuestionsItem[]) => {
    dispatch(toggleLoader(true));
    setTimeout(() => {
      setVisibleQuestions(renderMoreItems(arr1, arr2));
      dispatch(toggleLoader(false));
    }, 300);
  };

  const handleClickShowMoreButton = (arr: QuestionsItem[]) => {
    dispatch(toggleLoader(true));

    if (titleQuestionRef.current) {
      titleQuestionRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    setTimeout(() => {
      setVisibleQuestions(arr.slice(0, 5));
      dispatch(toggleLoader(false));
    }, 300);
  };

  const handleClick = isShowMore
    ? () => handleClickMoreButton(allQuestions, visibleQuestions)
    : () => handleClickShowMoreButton(visibleQuestions);

  return (
    <QuestionsSection>
      <TitleH2 ref={titleQuestionRef}>Часто задаваемые вопросы</TitleH2>
      <QuestionsList key={'faq-list'}>
        {visibleQuestions && renderQuestions(visibleQuestions as QuestionsItem[])}
      </QuestionsList>

      <QuestionsMoreButtonWrapper>
        <QuestionsMoreButton
          onClick={handleClick}>
          <span>{buttonText}</span>
        </QuestionsMoreButton>
      </QuestionsMoreButtonWrapper>
    </QuestionsSection>
  );
};

const QuestionsSection = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 50px 0;
`;

const QuestionsList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0 23px;
    margin-bottom: 30px;
`;

const QuestionsListItem = styled(InfoListItem)`
    max-width: 390px;
    flex-direction: column;
    margin: 0;
    padding: 13px;
    position: relative;
    overflow: hidden;
`;

const QuestionsItemTitle = styled.h3`
    font-size: 14px;
    font-weight: 600;
    color: #13ffee;
    line-height: 22px;
    letter-spacing: -0.02em;
    margin: 0 0 20px 0;
    pointer-events: none;
`;

const QuestionsItemTextWrapper = styled.div`
    height: 0;
    display: block;
    margin: 0 0 10px 0;
    overflow: hidden;
`;

const QuestionsItemText = styled(InfoItemText)`
    font-size: 14px;
    pointer-events: none;
    white-space: break-spaces;
`;

const ArrowItem = styled.span`
    width: 25px;
    height: 25px;
    display: block;
    background: transparent url('/assets/img/logo/arrow.svg') no-repeat center;
    position: absolute;
    bottom: 0;
    right: calc(50% - 10px);
`;

const QuestionsMoreButtonWrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;

`;

const QuestionsMoreButton = styled(InfoButton)`
`;

export default FrequentlyAskedQuestions;