import React, { useState } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import AboutSectionProps from '@/interfaces/AboutSection';
import TitleH2 from '@/components/ui/Title';
import { InfoButton, ShowButton } from '@/components/ui/InfoSection';


const AboutUsSection: React.FC<AboutSectionProps> = ({
                                                       title,
                                                       text
                                                     }): React.ReactElement => {
  const router = useRouter();
  const [active, setActive] = useState<boolean>(false);

  const handleClickButton = (): void => {
    router.push('/contacting');
  };

  const handleClickMoreButton = (e: any): void => {
    e.preventDefault();

    active ? setActive(false) : setActive(true);
  };

  return (
    <AboutUsContainer>
      <AboutUsTitle>{title}</AboutUsTitle>
      <AboutUsText>
        {text}
      </AboutUsText>
      <ShowButton onClick={(e: any) => handleClickMoreButton(e)}>{active ? 'Свернуть' : 'Развернуть'}</ShowButton>
      {active && (
        <AboutUsMoreText>
            Команда энтузиастов создала платформу Спортадиум, где собраны проверенные тренеры, а также предусмотрен функционал, необходимый для комфортной работы:<br /><br />
            - подробное анкетирование, чтобы тренеру была видна вся информация, которой вы сочтете нужным поделиться;<br />
            - персональный чат, куда ваш тренер будет присылать тренировочные планы и советы по питанию, а вы сможете задавать вопросы, получать советы по технике выполнения упражнений и давать любую обратную связь;<br />
            - функционал для учета динамики веса и замеров тела (можно хранить данные об объемах груди, талии, бедер, рук и других частей тела);<br />
            - раздел для хранения медиафайлов, к которому будет доступ у тренера, чтобы отслеживатьвизуальные изменения в теле, а также технику выполнения упражнений.
            <br />
            <br />
            Спортадиум будет развиваться, поэтому совсем скоро, помимо того функционала, который уже представлен, на сервисе появятся увлекательные графики, наглядно демонстрирующие прогресс, различная инфографика, статистика тренировок, динамика успехов и достижений и многое другое.
        </AboutUsMoreText>
      )}
      <AboutUsBtn margin={'0'} onClick={handleClickButton}>
        <span>Обратная связь</span>
      </AboutUsBtn>
    </AboutUsContainer>
  );
};

const AboutUsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 78px 0;
    z-index: 10;
`;

const AboutUsTitle = styled(TitleH2)`
    margin-bottom: 28px;
`;

const AboutUsText = styled.p`
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.8px;
    margin-bottom: 10px;
    padding: 0 13px;

    span {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.28px;
        color: #13ffee;
        margin: 0 0 0 5px;
    }

    @media (min-width: 390px) {
        font-size: 14px;
        padding: 0 23px;

        span {
            font-size: 13px;
        }
    }
`;

const AboutUsMoreText = styled(AboutUsText)``;

const AboutUsBtn = styled(InfoButton)<{ margin: string }>`
    margin-bottom: ${({ margin }) => margin || '0'};
    margin-top: 50px;
`;

export default AboutUsSection;
