import InfoSectionProps from '@/interfaces/InfoSection';
import UserRoleType from '@/enums/userRole';

export const AthletesSection: InfoSectionProps = {
  type: UserRoleType.Athlete,
  title: 'почему выбирают нас',
  image: 'rungirl-2.png',
  imageStyle: { width: '204px', height: '287px' },
  btnText:
    'Начните с регистрации, чтобы получить первую неделю бесплатно!<br/>После регистрации вы получите:<br/><br/>- чат с вашим тренером, возможность обмена медиафайлами;<br/><br/>- анкету для ввода данных о здоровье, питании и образе жизни;<br/><br/>- систему учета данных о динамике веса и замерах тела.',
  moreTextInfo:
    'В рамках первой бесплатной пробной недели, вы познакомитесь с тренером. Тренер изучит вашу анкету, составит планы первых тренировок, даст рекомендации по питанию.<br/>В ходе дальнейшей работы, тренер будет выходить с вами на связь несколько раз в неделю.<br/><br/>При общении с тренером вы получите:<br/>- новые планы тренировок;\n' +
      '- ответы на интересующие вопросы;\n' +
      '- советы по питанию;\n' +
      '- корректировку техники выполнения упражнений. \n <br> <br> Получите желаемый результат от тренировок в фитнес-зале с персональными онлайн-тренерами Спортадиум! <br><br>',
  stylesItems: {
    listStyle: {
      margin: '46px'
    },
    imageStyle: {
      margin: '59px'
    },
    textStyle: {
      margin: '20px'
    },
    buttonStyle: {
      margin: '20px'
    }
  },
  items: [
    {
      id: '1',
      text: 'Индивидуальный тренировочный план от профессиональных тренеров еженедельно.'
    },
    {
      id: '2',
      text: 'Предоставление индивидуальных рекомендаций по правильному питанию.'
    },
    {
      id: '3',
      text: 'Учет личных особенностей: травмы, ограничения, болезни.'
    },
    {
      id: '4',
      text: 'Коммуникация с тренером через встроенный чат.'
    }
  ],
  link: '/auth'
};

export default AthletesSection;
